<template>
  <div class="invest container">
    <div class="title">我的收益</div>

    <div class="can_sell">
      <div style="text-align: center;height: 50px;line-height: 50px">
        WF当前价格：{{price}}
      </div>
    </div>
    <div style="height: 30px"></div>


    <div class="header">


      <div>
        <div>
          <div>待领取收益</div>
          <span> {{rewardDetail.userHaveReward  | noZero }}<img src="../../assets/img/usdt-icon.png" /></span>
        </div>

        <div>
          <div>已领取收益</div>
          <span>{{rewardDetail.userEndReward | numFilter2 }}/{{rewardDetail.userReward}}<img src="../../assets/img/usdt-icon.png" /></span>
        </div>

        <div>
          <div>推荐奖</div>
          <span> {{rewardDetail.invite | numFilter5 }}<img src="../../assets/img/usdt-icon.png" /> </span>
        </div>
        <div>
          <div>团队奖</div>
          <span> {{rewardDetail.team | numFilter5 }} <img src="../../assets/img/usdt-icon.png" /></span>
        </div>

        <div>
          <div>平级奖</div>
          <span> {{rewardDetail.level | numFilter5 }}<img src="../../assets/img/usdt-icon.png" /> </span>
        </div>
        <div>
          <div>股东奖</div>
          <span> {{rewardDetail.share | numFilter5 }}<img src="../../assets/img/usdt-icon.png" /> </span>
        </div>



      </div>

      <button v-loading="loading" @click="getReward" >领取奖励</button>

    </div>


  </div>
</template>

<script>
import { ethers } from 'ethers'
import {initSigner} from "@/utlis";

import MinerJson from "@/contracts/Miner.json";
import NewMinerJson from "@/contracts/NewMiner.json";
import ERC20JSON from "@/contracts/ERC20.json";
export default {
  data() {
    return {
      contracts:{},
      address:null,
      price:0,
      loading:false,
      rewardDetail:{
        userHaveReward:0,
        userEndReward:0,
        userReward:0,
        invite:0,
        level:0,
        share:0,
        team:0,
        olduser:false,
      }
    }
  },
  async mounted() {

    let {address, provider, signer} = await initSigner()
    if (!address || !provider || !signer) return;
    this.address = address;
    this.contracts.Miner = new ethers.Contract(this.$common.SUPERFOMO.Miner, MinerJson, provider).connect(signer);
    this.contracts.MinerHelper = new ethers.Contract(this.$common.SUPERFOMO.MinerHelper, NewMinerJson, provider).connect(signer);
    this.contracts.WF = new ethers.Contract(this.$common.SUPERFOMO.WF, ERC20JSON, provider).connect(signer);

    this.getData();

  },

  methods: {
    getData()
    {
      this.contracts.MinerHelper.getTokenPrice().then(res => {
        console.log("price",res.toString());
        this.price = Number(ethers.utils.formatUnits(res, 18)).toFixed(10)
      })
      this.contracts.MinerHelper.earned(this.address).then(res2 => {
        console.log(Number(ethers.utils.formatUnits(res2, 18)));
        this.rewardDetail.userHaveReward =  Number(ethers.utils.formatUnits(res2, 18))
      })
      this.contracts.MinerHelper.userMap(this.address).then(res2 => {
        console.log(res2);
         this.rewardDetail.userEndReward =  Number(ethers.utils.formatUnits(res2.userEndReward, 18))
         this.rewardDetail.olduser = res2.olduser
         this.rewardDetail.userReward =  Number(ethers.utils.formatUnits(res2.userReward, 18))
      })
      this.contracts.MinerHelper.getTeamReward(this.address).then(res => {
        console.log(res);
        this.rewardDetail.invite =  Number(ethers.utils.formatUnits(res.invite, 18))
        this.rewardDetail.level =  Number(ethers.utils.formatUnits(res.level, 18))
        this.rewardDetail.share =  Number(ethers.utils.formatUnits(res.share, 18))
        this.rewardDetail.team =  Number(ethers.utils.formatUnits(res.team, 18))

       })
      this.contracts.WF.allowance(this.address, this.$common.SUPERFOMO.Miner).then(res => {
        this.allowanceWF = Number(ethers.utils.formatUnits(res, 18))
      })

    },
    async getReward() {
      this.loading = true;
      if (this.allowanceWF < 9999999999) {
        try {
          this.$message.warning(this.$t('app.noApproveInfo'))
          let tx = await this.contracts.WF.approve(this.$common.SUPERFOMO.Miner, ethers.constants.MaxUint256)
          await tx.wait()

        } catch (e) {
          console.error(e);
          this.loading = false;
          setTimeout(() => {
            this.$message.error(this.$t('app.ApproveFailed'));
          }, 1000)
          return;
        }
      }
      this.contracts.Miner.getReward({
        gasPrice: 1000000000
      }).then(async (result) => {
        await result.wait()
        this.$message.success("领取成功")
        this.getData();
        this.loading = false;
      }).catch((err) => {
        this.getData();
        console.log(err);
        this.loading = false;
        this.$message.error("领取失败")
      });


    },
  },
  components: {}
}
</script>

<style lang="scss" scoped>
.btn-not-active {
  cursor: no-drop;
  background: url(../../assets/img/btn-type-1.png) left top / 100% 100% no-repeat !important;
}

::v-deep .el-dialog {
  max-width: 7rem !important;
  margin: 20vh auto 0 !important;
  background: rgba(33, 33, 33, 0.7) !important;
  border: 1px solid;
  border-image: linear-gradient(-67deg, #00BA45, #45BBF3, #B285ED) 1 1 !important;
}

.dialog-content {
  display: flex;
  flex-direction: column;

  >span {
    font-size: .22rem;
    text-align: right;
    margin-bottom: .25rem;
  }

  >section {
    margin-top: .3rem;
    text-align: center;
    font-size: .22rem;
  }

  main>p {
    font-size: .26rem;
    font-weight: bold;
    color: #F06671;
    line-height: .38rem;
  }

  >button {
    color: #fff;
    height: .9rem;
    margin-top: .3rem;
    border: 1px solid #45BBF3;
    background: rgba(178, 133, 237, 0.8);
    font-size: .32rem;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.54);
  }

  .btns {
    display: flex;
    justify-content: space-between;
    width: 100%;

    >button {
      width: 47%;
      font-size: .32rem;
      font-weight: bold;
      color: #FFFFFF;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.54);
      margin-top: .32rem;
      height: .8rem;
      border: none;

      &:first-child {
        background: url(../../assets/img/btn-type-2.png) left top / 100% 100% no-repeat;
      }

      &:last-child {
        background: url(../../assets/img/btn-type-1.png) left top / 100% 100% no-repeat;

      }
    }
  }

  ::v-deep .el-input {
    font-size: .28rem;

    .el-input__inner {
      color: #fff;
      height: .8rem;
      background-color: rgba(85, 114, 241, 0.5) !important;
      border: 1px solid #45BBF3 !important;
    }
  }

  .max-input {
    position: relative;

    >button {
      top: 0.14rem;
      right: .14rem;
      position: absolute;
      color: #fff;
      width: 1rem;
      height: .52rem;
      border: .02rem solid;
      border-image: linear-gradient(0deg, #9672C5, #45BBF3) 10 10;
      background: linear-gradient(0deg, #9672C5 0%, #45BBF3 100%);
    }
  }
}

.invest {
  padding: 1.4rem 0.44rem 0.4rem;
  font-size: .26rem;

  .redelivery {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99;
    >img{
      width: 20px;
      right: 20px;
      top: 20px;
      position:absolute;
    }
    >div {
      width: 90%;
      max-width: 400px;
      background: url(../../assets/img/coin-bg.png) left top / 100% 100% no-repeat !important;
      h4{
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #45BBF3;
        line-height: 70px;
        text-shadow: 0px 3px 2px rgba(0,0,0,0.54);
      }
      .money {
        display: flex;
        align-items: center;
        justify-content:space-evenly;
        font-size: 13px;
        .active {
          height: 32px;
          background: rgba(178, 133, 237, 0.5);
          color: #FFFFFF;
          border: 1px solid #45BBF3;
        }

        .un_active {
          width: 63px;
          height: 32px;
          background: #777E90;
          border: 1px solid #A7A9AC;
        }
        >div{
          width: 60px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          border: 1px solid #45BBF3;
        }
      }
      .integral{
        width: 80%;
        margin: 0 auto;
        text-align: center;
        font-size: 14px;
        height: 45px;
        line-height: 45px;
        margin-top: 20px;
        background: url(../../assets/img/invest-item-head.png) left top / 100% 100% no-repeat !important;
      }
      .consume{
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        >div{
          font-size: 12px;
          span{
            color:#45BBF3;
          }
        }
      }
      .btn{
        width: 80%;
        height: 40px;
        margin: 20px auto;
        font-size: 15px;
        font-weight: 700;
        background-color: #B285ED;
        border: 1px solid #45BBF3;
        line-height: 40px;
        text-align: center;
      }
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    background: url(../../assets/img/coin-bg.png) no-repeat;
    background-size: 100% 100%;
    padding: .6rem .6rem;
    margin-bottom: 1rem;

    >section {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-size: .26rem;
      margin-bottom: .3rem;
    }

    >div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      font-size: .22rem;

      >div {
        margin-bottom: .3rem;
        width: 40%;
      }
    }

    >div>div>span,
    >section>span {
      margin-top: .25rem;
      display: flex;
      align-items: center;
      font-size: .3rem;
      color: #3E9DD2;
      line-height: .32rem;

      >img {
        width: .4rem;
        margin-left: .1rem;
      }
    }

    >section>span {
      justify-content: center;
    }

    button {
      height: .96rem;
      background: rgba(178, 133, 237, 0.8);
      border: 1px solid #45BBF3;
      font-size: .32rem;
      font-weight: bold;
      color: #FFFFFF;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.54);
    }

  }

  .empty {
    font-size: 0.28rem;
    padding: 0.4rem 0;

    .empty-bg {
      width: 2.9rem;
      height: 1.87rem;
      margin: 0 auto;
      background: url('../../assets/img/empty.png') left top / 100% 100% no-repeat;
    }

    .tips {
      margin-top: 0.2rem;
      text-align: center;
    }
  }

  #main {
    width: 100%;
    height: 4rem;
  }

  .title {
    width: 5.74rem;
    height: 1.66rem;
    line-height: 1.28rem;
    font-size: 0.36rem;
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
    background: url('../../assets/img/web-title-bg.png') left top / 100% 100% no-repeat;
  }

  .personInfo {
    padding: 10px 0;
    background: url(../../assets/img/bind-bg.png) no-repeat;
    background-size: 100% 100%;

    >div {
      padding: 5px 0;

      img {
        width: 30px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    background: url(../../assets/img/tz-item-bg.png) no-repeat;
    background-size: 100% 100%;
    padding: .6rem;
    margin-top: .3rem;

    .top {
      display: flex;
      flex-direction: column;

      .balance {
        display: flex;
        // flex-direction: column;
        justify-content: space-between;

        >div>span {
          color: #45BBF3;
        }

        >span {
          border-bottom: 1px solid #6ea7f1;
          font-weight: 700;
          background: linear-gradient(0deg, #45BBF3 0%, #B285ED 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          cursor: pointer;
        }
      }

      .tab {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: space-around;
        margin: .3rem 0 .6rem;

        >div {
          width: 23%;
          height: .64rem;
          line-height: .64rem;
          border: 1px solid #45BBF3;
        }

        .active {
          height: .64rem;
          background: rgba(178, 133, 237, 0.5);
          color: #FFFFFF;
          border: 1px solid #45BBF3;
        }

        .un_active {
          width: 63px;
          height: 32px;
          background: #777E90;
          border: 1px solid #A7A9AC;
        }
      }
    }

    >button {
      margin: .35rem 0 .6rem;
      width: 100%;
      height: .96rem;
      font-weight: bold;
      font-size: .32rem;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.54);
      color: #FFFFFF;
      background: rgba(178, 133, 237, 0.8);
      border: 1px solid #45BBF3;
    }
  }

  .transfer,
  .can_sell {
    background: url(../../assets/img/sss-bg.png) no-repeat;
    background-size: 100% 100%;
    padding: .25rem .5rem;
  }

  .transfer {
    display: flex;
    flex-direction: column;
    margin: .25rem 0;

    >div {
      display: flex;
      justify-content: space-between;
      width: 100%;
      line-height: .8rem;

      >div {
        display: flex;

        >span {
          color: #45BBF3;
        }

        text-align: center;
      }

      button {
        border: none;
        color: #fff;
        width: 1.66rem;
        height: .6rem;
        background: url(../../assets/img/btn-type-2.png) left top / 100% 100% no-repeat;
      }
    }
  }



  .my_invest {
    display: flex;
    flex-direction: column;
    width: 100%;

    .my_item {
      display: flex;
      flex-direction: column;
      background: url(../../assets/img/invest-item-bg.png) left top / 100% 100% no-repeat;
      padding: .45rem .45rem;
      margin-bottom: .4rem;

      >div,
      header {
        display: flex;
        justify-content: space-between;
      }

      header {
        align-items: center;
        margin-bottom: .5rem;

        h4 {
          width: 2.48rem;
          text-align: center;
          line-height: .66rem;
          font-size: .3rem;
          font-weight: bold;
          height: .66rem;
          font-weight: normal;
          background: url(../../assets/img/invest-item-head.png) left top / 100% 100% no-repeat;
        }
      }

      >div {
        &:last-child {
          font-size: .3rem;
          margin-top: .1rem;
        }

        >div {
          &:nth-of-type(1) {
            width: 65%;
          }

          &:nth-of-type(2) {
            width: 35%;
          }

          >span {
            margin-left: .5rem;
            font-size: .22rem;
            color: #45BBF3;
          }
        }
      }

    }
  }

  .mybtns {
    margin-top: -0.3rem;
    flex-direction: column;
    >button {
      border: none;
      width: 1.66rem;
      height: .6rem;
      color: #fff;
      background: url(../../assets/img/btn-type-2.png) left top / 100% 100% no-repeat;

      &:last-child {
        margin-top: .2rem;
      }
    }
  }
}
</style>
